import { Container, Row, Col, Figure } from 'react-bootstrap';
import FigureCaption from 'react-bootstrap/esm/FigureCaption';
import FigureImage from 'react-bootstrap/esm/FigureImage';
import FindMe from '../FindMe';

const HomePage = () => {
  return (
    <Container fluid>
      <Row>
        <Col className="left-pane mx-0 px-0 bg-light order-3 order-lg-1  col-12 col-lg-4">
          <Figure>
            <FigureImage src="/JoelAndMaggie.jpg" fluid />
            <FigureCaption className="text-center">
              Maggie and me taking a drive.
            </FigureCaption>
          </Figure>
        </Col>
        <Col className="center-pane px-5 h-100 order-1 order-lg-2 col-12 col-lg-4">
          <h1 className="display-3 pb-3">Welcome</h1>
          <p className="lead">
            My name is Joel Groomer. I'm a developer with training in iOS in
            both Swift and Objective-C. Currently, I am the Sr. Support Engineer
            for a SaaS company where we utilize Ruby on Rails 6. I'm also a big
            fan of JavaScript and I have dabbled in React and Angular, among
            other things.
          </p>
          <p className="lead">
            I'm currently working hard to rebuild this site from scratch in
            React, which is why nothing much is here yet.
          </p>
          <h2 className="display-5 pt-5">Please check back soon.</h2>
          <p className="align-text-bottom">Last updated: 10/29/22.</p>
        </Col>
        <Col className="order-2 order-lg-3 col-12 col-lg-4 bg-light">
          <Container className="right-pane col col-12 no-gutters bg-light h-100 pt-5">
            <FindMe />
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
