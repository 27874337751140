import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Col, Container, Row } from 'react-bootstrap';

const myLinks = [
  {
    icon: brands('twitter'),
    service: 'Twitter',
    handle: '@julltron',
    url: 'https://twitter.com/julltron',
    comment: "where I don't have all that much to say lately",
  },
  {
    icon: brands('instagram'),
    service: 'Instagram',
    handle: '@julltron',
    url: 'https://instagram.com/julltron',
    comment: 'where I mostly post pics of my many pets',
  },
  {
    icon: brands('github'),
    service: 'GitHub',
    handle: 'joelgroomer',
    url: 'https://github.com/joelgroomer',
    comment: 'where the code is, obvi',
  },
];

const FindMe = () => {
  return (
    <>
      <Row className="pb-4">
        <Col className="col-2 d-none d-lg-block" />
        <Col className="col-1" />
        <Col className="col-9">
          <h2>find me online</h2>
        </Col>
      </Row>
      {myLinks.map(myLink => {
        return (
          <div key={myLink.service}>
            <Row>
              <Col className="col-2 d-none d-lg-block" />
              <Col className="col-1">
                <FontAwesomeIcon icon={myLink.icon} />
              </Col>
              <Col className="col-5">
                <a href={myLink.url}>{myLink.handle}</a>
              </Col>
              <Col className="col-4" />
            </Row>
            <Row className="pb-3">
              <Col className="col-2 d-none d-lg-block" />
              <Col className="col-1" />
              <Col className="col-8">{myLink.comment}</Col>
              <Col className="col-1"></Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default FindMe;
